import React, { useContext, useState, useEffect } from 'react'

import { auth } from "../services/firebase"

import {
    createUserWithEmailAndPassword,
    deleteUser,
    linkWithCredential,
    applyActionCode,
    EmailAuthProvider,
    reauthenticateWithCredential,
    signInWithEmailAndPassword,
    sendEmailVerification,
    signOut,
    sendPasswordResetEmail,
    updateEmail,
    updatePassword
} from "firebase/auth"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null)
    const [loading, setLoading] = useState(true)

    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    function resendVerificationEmail() {
        return sendEmailVerification(currentUser);
    }

    function sendVerificationEmail(user) {
        return sendEmailVerification(user);
    }

    function linkAnonymousAccount(email, password) {
        try {
            const credential = EmailAuthProvider.credential(email, password)
            return linkWithCredential(auth.currentUser, credential)
        } catch (error) {
            console.log(error)
        }
    }

    function applyAuthActionCode(code) {
        return applyActionCode(auth, code)
    }

    function signin(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }

    function signout() {
        return signOut(auth)
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email)
    }

    function changeEmail(email) {
        return updateEmail(currentUser, email)
    }

    function changePassword(password) {
        return updatePassword(currentUser, password)
    }

    function reauthenticate(password) {
        var credential = EmailAuthProvider.credential(
            currentUser.email,
            password
        )

        return reauthenticateWithCredential(currentUser, credential)
    }

    function deleteAccount() {
        return deleteUser(currentUser)
    }

    useEffect(() => {    
        const unsubscribeIdToken = auth.onIdTokenChanged((user) => {
          setCurrentUser(user);
        });
    
        const unsubscribeAuthState = auth.onAuthStateChanged((user) => {
          setCurrentUser(user);
          setLoading(false);
        });
    
        return () => {
          unsubscribeIdToken();
          unsubscribeAuthState();
        };
      }, []);
      
    const value = {
        currentUser,
        signup,
        resendVerificationEmail,
        sendVerificationEmail,
        linkAnonymousAccount,
        applyAuthActionCode,
        signin,
        signout,
        resetPassword,
        changeEmail,
        changePassword,
        reauthenticate,
        deleteAccount,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
