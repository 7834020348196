import React from 'react';

import { Outlet } from "react-router-dom";

import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';

import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';

export default function Main() {
    const { signout } = useAuth();

    return (
        <Box
            sx={{
                maxWidth: '960px',
                mx: 'auto',
            }}
        >

            <Header >
                <Button
                    size="sm"
                    onClick={async function () {
                        await signout()
                    }} variant="plain" >
                    Logout
                </Button>

            </Header>

            <Outlet />

        </Box>

    );
};
