import React from 'react'

import { Timestamp } from 'firebase/firestore';

import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography'

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import { ImprovementRequest } from './types';

interface ImprovementRequestListItemProps {
    improvementRequest: ImprovementRequest;
    onClick: () => void;
}

function formatTimestamp(timestamp: Timestamp): string {
    const now = new Date();
    const timeDiff = now.getTime() - timestamp.toDate().getTime();
    const minutes = Math.floor(timeDiff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 1) {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const date = timestamp.toDate();
        return `${monthNames[date.getMonth()]} ${date.getDate()}`;
    }

    if (days === 1) {
        return 'Yesterday';
    }

    if (hours > 0) {
        const remainingMinutes = minutes % 60;
        return `${hours} hour${hours === 1 ? '' : 's'} and ${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'} ago`;
    }

    if (minutes > 0) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    }

    return 'Just now';
}

function ImprovementRequestListItem({ improvementRequest, onClick }: ImprovementRequestListItemProps) {
    return (
        <ListItem>
            <ListItemButton
                onClick={onClick}>
                <ListItemContent>
                    <Typography level="title-lg">{formatTimestamp(improvementRequest.createdAt)}</Typography>
                </ListItemContent>
                <KeyboardArrowRight />
            </ListItemButton>
        </ListItem>
    )
}

export default ImprovementRequestListItem