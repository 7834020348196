import React, { useEffect, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/joy/Link';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

import { useAuth } from '../../context/AuthContext';

type VerificationState = 'success' | 'error' | null;

const EmailVerificationHandler = () => {

  const [verificationState, setVerificationState] = useState<VerificationState>(null);

  const { applyAuthActionCode, resendVerificationEmail } = useAuth();


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const actionCode = urlParams.get('oobCode');

    if (mode === 'verifyEmail') {
      applyAuthActionCode(actionCode)
        .then(() => {
          setVerificationState('success');
        })
        .catch((error: any) => {
          console.log(error);
          setVerificationState('error');
        });
    }
  }, [applyAuthActionCode]);

  return (
    <>
      <Box
        component="main"
        sx={{
          my: 'auto',
          py: 2,
          pb: 5,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 400,
          maxWidth: '100%',
          mx: 'auto',
          borderRadius: 'sm',
        }}
      >
        
        {verificationState === 'success' && (
          <Box>
            <Typography component="h2" fontSize="xl2" fontWeight="lg">
              Email verification successful
            </Typography>
            <Typography level="body-md" sx={{ my: 1, mb: 3 }}>
              Your email has been successfully verified. You can now log in.
            </Typography>
            <Link underline="always" component={RouterLink} to="/login">
              Continue to login
            </Link>
          </Box>
        )}

        {verificationState === 'error' && (
          <Box>
            <Typography component="h2" fontSize="xl2" fontWeight="lg">
              Email verification failed
            </Typography>
            <Typography level="body-md" sx={{ my: 1, mb: 3 }}>
              There was an error verifying your email. Please try again or contact support.
            </Typography>
            <Button fullWidth
              color="primary"
              onClick={async () => {
                resendVerificationEmail();
              }}
            >
              Send new verification email
            </Button>

          </Box>
        )}

      </Box>

    </>
  );
}

export default EmailVerificationHandler;
