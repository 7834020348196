import React, { useState, useEffect } from "react";

import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";

import { db, storage } from "../services/firebase";
import { getDoc, doc, deleteDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, getBlob, getDownloadURL } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import "./audioStyles.css";

import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from '@mui/joy/Link';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Textarea from "@mui/joy/Textarea";

import Download from '@mui/icons-material/Download';

import { useAuth } from '../context/AuthContext';

import { ImprovementRequest } from './types';

export default function ImprovementRequestDetails() {

    const analytics = getAnalytics();

    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const [improvementRequest, setImprovementRequest] = useState<ImprovementRequest | null>(null);
    const [transcription, setTranscription] = useState('');
    const [downloadURL, setDownloadURL] = useState('');

    let { improvementRequestId } = useParams();

    const [showId, setShowId] = useState(false);
    const handleMouseEnter = () => setShowId(true);
    const handleMouseLeave = () => setShowId(false);

    const handleNoteChange = (e: any) => {
        setTranscription(e.target.value)
    }

    const downloadRecording = async () => {

        try {
            if (improvementRequest?.storageRef !== undefined) {
                const storageRef = ref(storage, improvementRequest?.storageRef || '');
                await getBlob(storageRef).then((blob: Blob) => {
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = storageRef.name;
                    link.dispatchEvent(new MouseEvent('click'));
                    console.log('Recording downloaded.');
                });
            }
        } catch (error) {
            console.log(error);
            if(error instanceof Error) {
                if(error.message === 'storage/object-not-found') {
                    alert("Could not download recording. It is not available.");
                } 
            } else{
                alert("Could not download recording. Error: " + error);
            }
        }
    }

    const updateTranscription = async () => {
        if( improvementRequest === null ) {
            return;
        }
        try {
            // Set improved transcription and change status to 'improved'
            const noteRef = doc(db, improvementRequest.noteRef);

            // Add  a collection to the noteRef
            const collectionRef = collection(noteRef, 'improvements');

            // Add a document to the collection
            await addDoc(collectionRef, {
                improvedTranscription: transcription,
                createdAt: serverTimestamp(),
                createdBy: currentUser?.uid,
            }).then(async () => {
                // Delete the improvement request
                const improvementRef = doc(db, `improvementRequests/${improvementRequestId}`);
                await deleteDoc(improvementRef);
                logEvent(analytics, 'improvement_request_handled');
                navigate("/", { replace: true });
            }).catch((error) => {
                console.error("Error adding document: ", error);
                alert("Failed adding the improvement to the note! Please try again. Error: " + error);
            });

        } catch (error) {
            console.log(error);
            alert("Failed updating note! Please try again. Error: " + error);
        }
    }

    async function fetchDownloadURL(improvementRequest: ImprovementRequest | null) {
        try {
            if (improvementRequest?.storageRef !== undefined) {
                const storageRef = ref(storage, improvementRequest?.storageRef || '');
                const downloadURL = await getDownloadURL(storageRef);
                setDownloadURL(downloadURL);
            }
        } catch (error) {
            console.log(error);
            if (error instanceof Error) {
                if (error.message === 'storage/object-not-found') {
                    alert("Could not get the streaming URL. The file is not available.");
                }
            } else {
                alert("Could not get the streaming URL. Error: " + error);
            }
        }
    }

    async function fetchImprovementRequestById(userId: string, docId: string) {
        console.log("Fetching improvement request by id: ", docId)
        try {
            const improvementRef = doc(db, `improvementRequests/${docId}`);
            const improvementRequestSnapshot = await getDoc(improvementRef);

            if (!improvementRequestSnapshot.exists()) {
                console.log("No such doc!");
                alert("Failed getting the improvement reques! Document not found.");
                return;
            }

            const improvementRequest = improvementRequestSnapshot.data() as ImprovementRequest;
            setImprovementRequest(improvementRequest);
            setTranscription(improvementRequest.transcription);

            fetchDownloadURL(improvementRequest);
        } catch (error) {
            console.error("Error getting the improvement request: ", error);
        }
    }

    // fetch the transcript from the server
    useEffect(() => {
        fetchImprovementRequestById(currentUser?.uid || '', improvementRequestId || '');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser?.uid, improvementRequestId]);

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                <Breadcrumbs size="sm">
                    <Link
                        underline="hover"
                        color="neutral"
                        fontSize="inherit"
                        component={RouterLink} to="/improvementRequests">
                        Improvement Requests
                    </Link>
                    <Link
                        underline="none"
                        color="neutral"
                        fontSize="inherit"
                    >
                        Improvement
                    </Link>
                </Breadcrumbs>

            </Box>

            <Box sx={{ height: 30 }} />
            {
                improvementRequest && (
                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <Typography level="h3">
                            {improvementRequest?.createdAt.toDate().toLocaleString()}
                        </Typography>
                        <Typography
                            level="body-md"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {showId ? improvementRequest.userId.slice(0, 6)+'•'.repeat(10) : '••••••••'}
                        </Typography>
                    </Box>
                )
            }

            <Textarea
                sx={{ marginBottom: 3 }}
                variant="soft"
                color="neutral"
                minRows={2}
                value={transcription}
                disabled={false}
                onChange={handleNoteChange}
                placeholder="The transcribed note will appear here."
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                <AudioPlayer
                    autoPlay={false}
                    autoPlayAfterSrcChange={false}
                    src={downloadURL}
                    onPlay={e => console.log("onPlay")}
                    showSkipControls={false}
                    loop={false}
                    layout="horizontal"
                    customAdditionalControls={[]} 
                    onPlayError={e => {
                        console.log("onPlayError " + e)
                    }}
                />
                <Button
                    size="sm"
                    variant="plain"
                    endDecorator={<Download />}
                    onClick={downloadRecording}>
                    Download
                </Button>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                <Button
                    size="md"
                    variant="solid"
                    color="primary"
                    fullWidth
                    onClick={updateTranscription}
                >
                    Update transcription
                </Button>
            </Box>

        </Box>

    );
};
