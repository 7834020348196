import React from 'react';
import { Box } from '@mui/material';
import Alert from '@mui/joy/Alert';

export default function NotFound() {

    return (
        <Box
            sx={(theme) => ({
                width: '100vw',
                height: '100vh',
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', // Add this to center vertically
                backdropFilter: 'blur(4px)',
                backgroundColor: 'rgba(255 255 255 / 0.6)',
                [theme.getColorSchemeSelector('dark')]: {
                    backgroundColor: 'rgba(19 19 24 / 0.4)',
                },
            })}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Add this to prevent stretching
                }}
            >
                <Alert color={'danger'} sx={{ mb: 2 }}>
                    404 - Not Found
                </Alert>
                <Alert variant="soft">
                    The page you are looking for does not exist. Please check the URL or return to the homepage.
                </Alert>
            </Box>
        </Box>
    );
};
