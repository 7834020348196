import * as React from 'react';

import { useNavigate, Link as RouterLink } from "react-router-dom";

import Link from '@mui/joy/Link';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';

import { useAuth } from '../../context/AuthContext';

import JoySnackbar from "../../components/JoySnackbar";

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function Login() {

  const { signin } = useAuth();
  const navigate = useNavigate();
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');

  return (
    <>
      <Box
        component="main"
        sx={{
          my: 'auto',
          py: 2,
          pb: 5,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 400,
          maxWidth: '100%',
          mx: 'auto',
          borderRadius: 'sm',
          '& form': {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          },
          [`& .${formLabelClasses.asterisk}`]: {
            visibility: 'hidden',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            mb: 4,
          }}>
          <Typography level="h1">
            Transcript Tailor
          </Typography>
        </Box>

        <form
          onSubmit={async (event: React.FormEvent<SignInFormElement>) => {
            event.preventDefault();
            const formElements = event.currentTarget.elements;
            const data = {
              email: formElements.email.value,
              password: formElements.password.value,
            };
            try {
              await signin(data.email, data.password);
              navigate("/");
            } catch (err: any) {
              setError(err.message);
              setOpenToast(true);
              console.log(err)
            }
          }}
        >
          <FormControl required>
            <FormLabel>Email</FormLabel>
            <Input placeholder="Enter your email" type="email" name="email" />
          </FormControl>

          <FormControl required>
            <FormLabel>Password</FormLabel>
            <Input placeholder="•••••••" type="password" name="password" />
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }} />

          <Button type="submit" fullWidth>
            Log in
          </Button>

        </form>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            my: 1,
          }}>

          <Link underline="always" component={RouterLink} to="/reset-password">
            Forgot password?
          </Link>

        </Box>
      </Box>

      <JoySnackbar
        open={openToast}
        onOpenChange={setOpenToast}
        sx={{backgroundColor: 'red', borderColor: 'red'}}
      >
        <Typography textColor={'common.white'}>
          {error}
        </Typography>
      </JoySnackbar>

    </>
  );
}
