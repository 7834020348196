import React from "react";

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from "@mui/joy/CssBaseline";
import GlobalStyles from '@mui/joy/GlobalStyles';
import { Box } from '@mui/joy';

import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./auth/PrivateRoute";

import * as Toast from "@radix-ui/react-toast";

import AuthPageLayout from './pages/Auth/AuthPageLayout';
import Login from './pages/Auth/Login';
import ResetPassword from "./pages/Auth/ResetPassword";
import EmailVerificationHandler from "./pages/Auth/EmailVerificationHandler";

import NotFound from './pages/NotFound'
import Main from "./pages/Main";

import ErrorBoundary from './components/ErrorBoundary';
import ImprovementRequests from './components/ImprovementRequests';
import ImprovementRequestDetails from './components/ImprovementRequestDetails';

import '@fontsource/public-sans';
import '@fontsource/alegreya';

import customTheme from './theme';

function App() {

  return (
    <CssVarsProvider
      defaultMode="light"
      disableTransitionOnChange
      theme={customTheme}
    > 
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '40vw', // must be `vw` only
            '--Form-maxWidth': '700px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />

      <Router>
        <AuthProvider>
          <Toast.Provider swipeDirection="right">
            <ErrorBoundary>

              <Routes>
                <Route path="/" element={<PrivateRoute />}>
                  <Route index element={<Navigate to="/improvementRequests" />} />
                  <Route element={<Main />}>
                    <Route path="/improvementRequests" element={<ImprovementRequests />} />
                    <Route path="/improvementRequests/:improvementRequestId" element={<ImprovementRequestDetails />} />
                  </Route>
                </Route>
                <Route element={<AuthPageLayout />}>
                  <Route path="/auth/action" element={<EmailVerificationHandler />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>

            </ErrorBoundary>

            { /* This is the Toast Viewport */}
            <Box
              component={Toast.Viewport}
              sx={{
                "--viewport-padding": "25px",
                position: "fixed",
                bottom: 0,
                right: 0,
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                padding: "var(--viewport-padding)",
                gap: "10px",
                width: "390px",
                maxWidth: "100vw",
                margin: 0,
                listStyle: "none",
                zIndex: 2147483647,
                outline: "none"
              }}
            />

          </Toast.Provider>
        </AuthProvider>
      </Router>
    </CssVarsProvider>
  );
}

export default App;
