import { extendTheme } from '@mui/joy/styles';

declare module '@mui/joy/styles' {
  interface PaletteBackgroundOverrides {
    level4: true;
    accent: true;
  }
}

const theme = extendTheme({
  components: {
    JoyTextarea:{
      styleOverrides: {
        root: {
          backgroundColor: '#FFD2C3',
          color: '#5D4445',
        },
      },
    },
    JoyChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFD2C3',
          color: '#5D4445',
        },
      },
    },
    JoyLink: {
      styleOverrides: {
        root: {
          color: '#262422',
          underlineColor: '#262422',
          textDecorationColor: '#262422',
        },
      },
    },
  },
  fontFamily: {
    display: "'Inter', var(--joy-fontFamily-fallback)",
    body: "'Inter', var(--joy-fontFamily-fallback)"
  },
  typography: {
    h1: {
      fontFamily: [
        'Alegreya', // specify the font family you want to use
        'serif;',
      ].join(','),
      fontWeight: 500,
    },
    h3: {
      color: "#D36C65",
      fontWeight: 500,      
    },
  },
  colorSchemes: {
    "light": {
      "palette": {
        "background": {
          "body": "#FFF6F2",
          "surface": "#FFFFFF",
          "popup": "#FFF6F2",
          "level4": "#FFD2C3",
          "accent": "#F57661",
        },
        "text": {
          "primary": "#262422",
          "secondary": "#D36C65",
        },
        "primary": {
          "solidBg": "#2074E8",
        },
        "success": {
          "500": "#D36C65",
        },
        "warning": {
          "500": "#D3232F"
        },        
      }
    },
    dark: {
      palette: {}
    }
  }
})

export default theme;