// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {ReCaptchaEnterpriseProvider, initializeAppCheck} from 'firebase/app-check'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage } from "firebase/storage";

import {
  getFirestore,
} from "firebase/firestore";

// Your web app's Firebase configuration
// MeasurementId below is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app, "europe-west1");
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Initialize App Check with the reCAPTCHA enterprise key
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_FIREBASE_APPCHECK_RECAPTCHA_KEY),
  isTokenAutoRefreshEnabled: true,
})

if (process.env.REACT_APP_FIREBASE_USE_LOCAL_FUNCTIONS === "true") {
  console.log("Using local functions")
  connectFunctionsEmulator(functions, "localhost", 5001);
}
if (process.env.REACT_APP_FIREBASE_USE_LOCAL_AUTH === "true") {
  console.log("Using local auth")
  connectAuthEmulator(auth, "http://localhost:9099");
}

export default functions