import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';

import { useAuth } from '../../context/AuthContext';

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
}
interface ResetPasswordFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function ResetPassword() {
  const [open, setOpen] = useState<boolean>(false);
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <Box
        component="main"
        sx={{
          my: 'auto',
          py: 2,
          pb: 5,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 400,
          maxWidth: '100%',
          mx: 'auto',
          borderRadius: 'sm',
          '& form': {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          },
          [`& .${formLabelClasses.asterisk}`]: {
            visibility: 'hidden',
          },
        }}
      >

        <Box>
          <Typography component="h2" fontSize="xl2" fontWeight="lg">
            Forgot your password?
          </Typography>
          <Typography level="body-md" sx={{ mt: 1, mb: 3 }}>
            Enter your email address and we will send you a link to reset your password.
          </Typography>
        </Box>

        <form
          onSubmit={async (event: React.FormEvent<ResetPasswordFormElement>) => {
            event.preventDefault();
            const formElements = event.currentTarget.elements;
            const data = {
              email: formElements.email.value,
            };
            try {
              await resetPassword(data.email);
              setOpen(true);
            } catch (err) {
              console.log(err)
            }
          }}
        >
          <FormControl required>
            <FormLabel>Email</FormLabel>
            <Input placeholder="Enter your email" type="email" name="email" />
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
          </Box>
          <Button type="submit" fullWidth>
            Reset password
          </Button>
        </form>
      </Box>

      <Modal
        aria-labelledby="close-modal-title"
        open={open}
        onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
          setOpen(false);
          navigate("/");
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: 300,
            borderRadius: 'md',
            p: 3,
          }}
        >
          <ModalClose variant="outlined" />
          <Typography
            id="close-modal-title"
            level="h4"
          >
            Password reset email sent
          </Typography>
          <Typography
            level="body-md"
            sx={{ my: 1 }}
          >
            Check your inbox for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
          </Typography>
        </Sheet>
      </Modal>

    </>
  );
}
