import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from "react-router-dom";

import { collection, query, orderBy, startAfter, limit, onSnapshot, DocumentSnapshot } from 'firebase/firestore';

import { db } from "../services/firebase";

import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import Box from '@mui/joy/Box';
import ListDivider from '@mui/joy/ListDivider';

import FavoriteIcon from '@mui/icons-material/Favorite';
import CircularProgress from '@mui/material/CircularProgress';

import ImprovementRequestListItem from './ImprovementRequestListItem';

import { ImprovementRequest } from './types';

import Lottie from "lottie-react";
import confetti_1 from "../assets/animations/confetti_1.json";
import confetti_2 from "../assets/animations/confetti_2.json";
import confetti_3 from "../assets/animations/confetti_3.json";
import confetti_4 from "../assets/animations/confetti_4.json";
import confetti_5 from "../assets/animations/confetti_5.json";
import confetti_6 from "../assets/animations/confetti_6.json";
import confetti_7 from "../assets/animations/confetti_7.json";
import confetti_8 from "../assets/animations/confetti_8.json";
import confetti_9 from "../assets/animations/confetti_9.json";

const PAGE_SIZE = 10;

export default function ImprovementRequests() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)

    const [improvementRequests, setImprovementRequests] = useState<ImprovementRequest[]>([]);
    const [lastDoc, setLastDoc] = useState<DocumentSnapshot | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [confettiAnimationFinished, setConfettiAnimationFinished] = useState<boolean>(false);

    const confetties = [confetti_1, confetti_2, confetti_3, confetti_4,
        confetti_5, confetti_6, confetti_7, confetti_8, confetti_9];

    const unsubscribeRef = useRef<(() => void) | null>(null);
    
    const fetchImprovementRequests = async (lastDocument: DocumentSnapshot | null = null) => {
        setLoading(true);
        console.log('Fetching improvement requests...');

        const improvementRequestsRef = collection(db, 'improvementRequests');
        let improvementRequestsQuery = query(improvementRequestsRef, orderBy('createdAt', 'desc'), limit(PAGE_SIZE));

        if (lastDocument) {
            improvementRequestsQuery = query(improvementRequestsRef, orderBy('createdAt', 'desc'), startAfter(lastDocument), limit(PAGE_SIZE));
        }

        const unsubscribe = onSnapshot(improvementRequestsQuery, (snapshot) => {
            if (snapshot.empty) {
                setHasMore(false);
                setLoading(false);
                return;
            }

            const newImprovementRequests = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as ImprovementRequest));

            setImprovementRequests((prevImprovementRequests) => [
                ...prevImprovementRequests,
                ...newImprovementRequests.filter(
                    (newRequest) => !prevImprovementRequests.some((prevRequest) => prevRequest.id === newRequest.id))
            ]);

            setHasMore(snapshot.docs.length === PAGE_SIZE)
            setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
            setLoading(false);
        }, (error) => {
            setLoading(false);
            console.log(error);
        });

        return unsubscribe
    };

    useEffect(() => {
        fetchImprovementRequests().then(unsubscribe => {
            unsubscribeRef.current = unsubscribe;
        });

        return () => {
            unsubscribeRef.current?.();
            setImprovementRequests([]);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoadMore = () => {
        if (!loading && lastDoc) {
            fetchImprovementRequests(lastDoc);
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 3, marginTop: 2 }}>
                <Typography level="h3" endDecorator={<FavoriteIcon color='success' />}>
                    Transcripts that need some
                </Typography>
            </Box>

            {loading ? (
                // Loading state
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress />
                </Box>
            ) : improvementRequests.length === 0 ? (
                // No data state
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
                    <Typography level="h4" textColor="#757575">
                        Jay! all done!
                    </Typography>
                    <Box sx={{
                        display: improvementRequests.length === 0 && !loading && !confettiAnimationFinished ? 'flex' : 'none',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}>
                        <Lottie
                            animationData={confetties[Math.floor(Math.random() * confetties.length)]}
                            loop={false}
                            onComplete={() => {
                                setConfettiAnimationFinished(true);
                            }}
                            autoplay={improvementRequests.length === 0 && !loading && !confettiAnimationFinished}
                        />
                    </Box>
                </Box>
            ) : (
                // Data loaded state
                <Box sx={{ p: 2 }}>
                    <List sx={{ bgcolor: 'white' }}>
                        {improvementRequests.map((improvementRequest) => (
                            <React.Fragment key={improvementRequest.id}>
                                <ImprovementRequestListItem
                                    onClick={() => navigate(`/improvementRequests/${improvementRequest.id}`)}
                                    improvementRequest={improvementRequest} />
                                <ListDivider inset="gutter" />
                            </React.Fragment>
                        ))}
                    </List>
                    <Box sx={{ p: 1, textAlign: 'center' }}>
                        <Button variant="plain" disabled={!hasMore} onClick={handleLoadMore}>
                            Load more requests
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
};