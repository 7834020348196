import React from 'react';
import { useRouteError } from 'react-router-dom';

// Define a custom ErrorType interface
interface ErrorType {
  statusText?: string;
  message?: string;
}

// Type guard function to check if an object is of type ErrorType
function isErrorType(obj: any): obj is ErrorType {
  return typeof obj === 'object' && ('statusText' in obj || 'message' in obj);
}

export default function ErrorPage() {
  const error = useRouteError();

  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>
          {isErrorType(error)
            ? error.statusText || error.message
            : 'Unknown error'}
        </i>
      </p>
    </div>
  );
}